import React from 'react';
import { useDispatch } from 'react-redux';

import SEO from '../components/SEO';
import Login from '../components/login';
import '../components/login/style.scss';
import Footer from '../components/footer';
import Header from '../components/header/header';
import AuthStateProvider from '../components/authStateProvider';

const LoginPage = (props) => {
  const dispatch = useDispatch();
  const handleLogin = (action) => {
    dispatch(action);
  };

  return (
    <AuthStateProvider authRequired={false} className="h-100 bg-grey" {...props}>
      <SEO title="Subscripton Levels" />
      <Header hideMiddle={true} show={true} />
      <div className="bg-grey height-100vh">
        <div className="login-page-form-container">
          <div className="login-component-holder">
            <Login onLogin={handleLogin} />
          </div>
        </div>
        <Footer />
      </div>
    </AuthStateProvider>
  );
};
export default LoginPage;
